$(document).foundation();

// Show message that flight was added - this is just a wirefram hack solution.
if (top.location.pathname === '/index-flightInfo.html') {
	$('#reveal-message-flight-added').foundation('open');
}

if (top.location.pathname === '/design/ezy-wires/index-flightInfo.html') {
	$('#reveal-message-flight-added').foundation('open');
}
